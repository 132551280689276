/* .editModel {
  width: 80%;
  max-width: 90% !important;

} */
.modal-content{
  background-color: #202124;
}

#contained-modal-title-vcenter {
  padding-left: 0px;
  margin-left: 0px;
  margin: 0px;
  font-size: 1.5rem;
}

.close ::before {
  content: "X";
  visibility: visible;
  color: white;
  font-weight: bold;
}

.sr-only::before {
  visibility: hidden;
}

.close {
  /*background-color: red; */
  visibility: hidden;
  position: absolute;
  right: 12px;
  top: 10px;
  width: 20px;
  height: 20px;
  background-color: rgb(247, 12, 12, 0.5);
}
