@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
html,
body {
  height: 100%;
  /* background: #2a2b2e; */
  /* width: 100%; */
  margin: 0px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-family: "Roboto", sans-serif;
}

html,
body,
#root,
.App {
  height: 100%;
}

.body-container {
  display: grid;
  height: 100%;
  grid-template-columns: 12fr;
  grid-template-rows: 1fr 10fr 1fr;
}
.body-container > div {
  border: 1px solid black;
}

.header {
  background: #1f2023;
  border: 1px #5e5e5e solid;
  display: grid;
  grid-template-columns: 2fr 10fr;
}

.title {
  justify-self: center;
  align-self: center;
  font-size: 2rem;
  font-weight: 500;
  color: white;
}
.logout {
  justify-self: end;
  align-self: center;
  margin-right: 1rem;

  /* color: white; */
}
.logout > p {
  color: white;
  font-size: 10px;
  margin-top: auto;
  margin-bottom: 100px;
}

.profile-wrapper {
  background: #202124;
  /* display:#2a2b2e none; */
}

.profile-content-wrapper {
  width: 100%;
  /* max-width: 100vw; */
  display: grid;
  grid-template-columns: 2fr 10fr;
}
.profile-content-wrapper > div {
  border: 1px solid black;
}

.content-wrapper {
  background: #202124;
  display: grid;
  grid-template-rows: 1fr 11fr;
}
.content-table-wrapper {
  max-width: 100%;
  justify-self: stretch;
  font-size: 1rem;
}
@media only screen and (max-width: 1400px) {
  .content-table-wrapper {
    font-size: 0.9rem;
    padding-right: 0px;
    padding-right: 0px;
  }
}

.footer {
  background: #1f2023;
  border: 2px solid #454546;
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
}
.footer > p {
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: white;
  opacity: 0.5;
  font-size: 1em;
}

.alert-shown {
  opacity: 1;
  transition: all 250ms linear;
}

.alert-hidden {
  opacity: 0;
  transition: all 250ms linear 2s;
}

.content-nav-btn-wrapper {
  /* margin-top: 0px;
  margin-bottom: 0px;
  align-self: start; */
  margin-left: 1.5rem;
  display: flex;
  flex-wrap: nowrap;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  height: 2rem;
}
.custom-btn {
  /* height: 7.3vh; */
  height: 3rem;
  background: "FFFFFF";
  border-bottom: none;
}

/* Here we design the custom detail panel for VIPNoteTable-- Starts */
.custom-detail-panel-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: #515151; */
}

.custom-detail-panel {
  /* opacity: 0.5; */
  width: 50%;
  margin: 20px;
  font-size: 20;
  color: inherit;
  text-align: center;
  /* background: rgb(172, 88, 88); */
  background-color: inherit;
  /* background: #515151; */

  /* mix-blend-mode: difference; */
  display: grid;
  /* grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr); */
  grid-column-gap: 1rem;
  grid-row-gap: 0.5rem;
  grid-template-areas:
    "id id"
    "createdby  createdAt"
    "updatedby  updatedAt";
}

.custom-detail-panel-note_id {
  font-size: large;
  grid-area: id;
  justify-self: center;
  /* text-decoration: underline; */
}

.custom-detail-panel-user_fullname {
  grid-area: createdby;
  justify-self: center;
}

.custom-detail-panel-createdAt {
  grid-area: createdAt;
  justify-self: center;
}

.custom-detail-panel-updated_by_user_fullname {
  grid-area: updatedby;
  justify-self: center;
}

.custom-detail-panel-updatedAt {
  grid-area: updatedAt;
  justify-self: center;
}

/* Here we design the custom detail panel for VIPNoteTable-- Ends */

.MuiTableRow-hover:hover {
  background-color: red;
}

.custom-detail-panel-wrapper {
  animation: animatename 0.3s alternate;
  /* animation-direction: reverse; */
}

@keyframes animatename {
  0% {
    opacity: 0.5;
    transform: scaleY(0);
    transform-origin: 100% 0%;
    height: 0px;
  }

  100% {
    transform: scaleY(1);
    transform-origin: 100% 0%;
    height: 130px;
  }
}
.tableIcon {
  transition: transform 0.5s ease-in-out;
}

.tableIcon:hover {
  transform: rotate(360deg);
}

.downArrowImg {
  transform: rotate(180deg);
}

/* .editModel {
  width: 80%;
  max-width: 90% !important;

} */
.modal-content{
  background-color: #202124;
}
#contained-modal-title-vcenter {
  padding-left: 0px;
  margin-left: 0px;
  margin: 0px;
  font-size: 1.5rem;
}

.close ::before {
  content: "X";
  visibility: visible;
  color: white;
  font-weight: bold;
}

.sr-only::before {
  visibility: hidden;
}

.close {
  /*background-color: red; */
  visibility: hidden;
  position: absolute;
  right: 12px;
  top: 10px;
  width: 20px;
  height: 20px;
  background-color: rgb(247, 12, 12, 0.5);
}

/* .editModel {
  width: 80%;
  max-width: 90% !important;

} */
.modal-content{
  background-color: #202124;
}


#contained-modal-title-vcenter {
  padding-left: 0px;
  margin-left: 0px;
  margin: 0px;
  font-size: 1.5rem;
}

.close ::before {
  content: "X";
  visibility: visible;
  color: white;
  font-weight: bold;
}

.sr-only::before {
  visibility: hidden;
}

.close {
  /*background-color: red; */
  visibility: hidden;
  position: absolute;
  right: 12px;
  top: 10px;
  width: 20px;
  height: 20px;
  background-color: rgb(247, 12, 12, 0.5);
}

/* .editModel {
  width: 80%;
  max-width: 90% !important;

} */
.modal-content{
  background-color: #202124;
}
#contained-modal-title-vcenter {
  /* background-color: #202124; */
  padding-left: 0px;
  margin-left: 0px;
  margin: 0px;
  font-size: 1.5rem;
}

.close ::before {
  content: "X";
  visibility: visible;
  color: white;
  font-weight: bold;
}

.sr-only::before {
  visibility: hidden;
}

.close {
  /*background-color: red; */
  visibility: hidden;
  position: absolute;
  right: 12px;
  top: 10px;
  width: 20px;
  height: 20px;
  background-color: rgb(247, 12, 12, 0.5);
}

/* .editModel {
  width: 80%;
  max-width: 90% !important;

} */
.modal-content {
  background-color: #202124;
}
#contained-modal-title-vcenter {
  /* background-color: #202124; */
  padding-left: 0px;
  margin-left: 0px;
  margin: 0px;
  font-size: 1.5rem;
}

.close ::before {
  content: "X";
  visibility: visible;
  color: white;
  font-weight: bold;
}

.sr-only::before {
  visibility: hidden;
}

.close {
  /*background-color: red; */
  visibility: hidden;
  position: absolute;
  right: 12px;
  top: 10px;
  width: 20px;
  height: 20px;
  background-color: rgb(247, 12, 12, 0.5);
}

.userLevelAndPasswordCommon {
  font-weight: 900;
  font-size: larger;
  /* display: inline-block;
  color: white;
  border: 1px solid #515151;
  box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.2);
  vertical-align: middle;
  width: 100%;
  height: 100%;
  padding: 0.8rem;
  text-align: center; */
}

.userLevelAdmin {
  /* background: rgb(52, 52, 182); */
  color: rgb(185, 87, 42);
  color: rgba(26, 140, 197, 0.842);
}

.userLevelUser {
  /* background: rgb(34, 145, 34); */
  color: rgb(34, 145, 34);
}

.passwordDefault {
  color: rgb(218, 46, 46);
}
.passwordUpdated {
  color: rgb(34, 110, 145);
}



/* .editModel {
  width: 80%;
  max-width: 90% !important;

} */
.modal-content{
  background-color: #202124;
}

#contained-modal-title-vcenter {
  padding-left: 0px;
  margin-left: 0px;
  margin: 0px;
  font-size: 1.5rem;
}

.close ::before {
  content: "X";
  visibility: visible;
  color: white;
  font-weight: bold;
}

.sr-only::before {
  visibility: hidden;
}

.close {
  /*background-color: red; */
  visibility: hidden;
  position: absolute;
  right: 12px;
  top: 10px;
  width: 20px;
  height: 20px;
  background-color: rgb(247, 12, 12, 0.5);
}


Form{
	/*background-color:#4c4d4f;
	size:200px;
	color:white;
	padding: 80px;
  //margin-top:130px;
  //margin-left: 800px;
  //margin-right:800px;
  margin:200px;
  border-radius:10px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);*/
  max-width: 500px;
  min-width: 300px;
  max-height: 700px;
  width: 30%;
  height: 70%;
  margin: 110px auto;
  color:white;
  background-color: #4c4d4f;
  border-radius: 25px;
  padding:50px;
}
@media screen and (max-width: 1920px) {
	Form{
		width:20%;
		margin-left:40%;
		margin-right:40%;
		height:50%;
		margin-bottom: 0px;
		padding:30px;
	}
  }

.logintitle{
	text-align: center;
}
@media screen and (max-width: 1920px) {
	.logintitle{
		font-size:20px;
	}
  }

.mb-3{
	font-size:30px;
}
@media screen and (max-width: 1920px) {
	.mb-3{
		font-size:15px;
	}
  }

.mb-2{
	font-size:20px;
}
@media screen and (max-width: 1920px) {
	.mb-2{
		font-size:15px;
	}
  }

Navbar{
	width:40px;
	margin:200px;
}
.loginButton{
	/*margin-top:20px;
	margin-left:150px;
	text-align:center;
	height:60px;
	width:120px;
	font-size: 25px;*/
	max-width: 200px;
  min-width: 100px;
  max-height: 150px;
  width: 50%;
  height: 70%;
  margin-top:10px;
  padding:10px;
  margin-left:25%;
  margin-right:25%;
  font-size: 25px;
}
@media screen and (max-width: 1920px) {
	.loginButton{
		font-size:15px;
	}
  }
.formtext{
	height: 50px;
	font-size:20px;
	
}
@media screen and (max-width: 1920px) {
	.formtext{
		height: 35px;
		font-size:15px;
	}
  }
.logoutButton{
	font-size:20px;
}
.or{
	text-align: center;
}
.modalButton{
	text-align: center;
	margin-left: 860px;
	height:40px;
	width:300px;
}
.modalTitle{
	text-align: center;
	margin-left: 100px;
	font-size: 20px;
	font-weight: bold;
}

.App {      
  text-align: center;      
}  
.nav-item{
  font-size: 40px;
  padding:15px;

}
.nav-logout{
	font-size: 30px;
	padding:10px;
	text-align: right;
	margin-left: 1600px;
}    
.navheader{            
  color :white;      
  background-color:#11142e;     
}

body{
	/* background-color:#ADEFD1FF;
	background: linear-gradient(90deg, rgba(120, 115, 191, 1) 0%, rgba(68, 68, 190, 1) 31%, rgba(15, 189, 224, 1) 90%); */
	/* overflow-x:hidden;
	overflow-y:hidden; */
	background-color: rgb(7, 3, 32); /* For browsers that do not support gradients */
    background-image: linear-gradient(to right, rgb(29, 29, 29) , rgb(66, 66, 66));
}
.loginpage{
	overflow-x:hidden;
	overflow-y:hidden;
}

/* .footer{
	margin-top:90px;
	padding:15px;
	text-align: center;
	background-color: #11142e;
	text-align: bottom;
} */


.sidebar1{
	float: left;
  width: 100%;
  height: 100%;
  background:#2a2b2e;
 
}
.bodyBox{
	margin-top:100px;
	margin-left: 150px;
	float: left;
  width: 800px;
  height: 600px;
  background: #ccc;
  
}

	.card{
		width:100%;
		
	}
	.loginImg{
		margin-top: 2px;
		margin-left:25%;
		margin-right:25%;
		width:50%;
		display: flex;
		justify-content: center;
		align-items: center;
		opacity:1;
		
	}
	
.img1{
	/* margin-top: 10px; */
	margin-left:25%;
	margin-right:25%;
	width:50%;
	display: flex;
    justify-content: center;
    align-items: center;
	opacity:0.9;
	
}
/* .card-img{
	margin-top: 30px;
	width:25%;
	display: flex;
    justify-content: center;
    align-items: center;
} */
@media screen and (max-width: 1920px) {
	.img1{
		/* margin-top: 10px; */
		width:40%;
		margin-left:30%;
		margin-right:30%;
	}
  }
.profileButton{
	
	margin-top:15px;
	font-size: 25px;
	margin-left:15%;
	margin-right:15%;
	width:70%;
	height:100%;
	/* display: flex;
    justify-content: center;
    align-items: center; */
}
@media screen and (max-width: 1920px) {
	.profileButton{
	  font-size: 14px;
	}
  }
.profilelist{
	color:white;
	
}
.profilelist1{
	padding:15px;
	font-size: 20px;
	color:white;
	font-weight: bold;
}
@media screen and (max-width: 1600px) {
	.profilelist1{
		padding:5px;
		font-size: 15px;
		color:white;
		font-weight: bold;
	}
  }
.cpButton{
	margin-top:20px;
	margin-bottom: 15%;
	margin-left: 15%;
	width:70%;
	height: 100%;
	font-size: 25px;

}
@media screen and (max-width: 1920px) {
	.cpButton{
	  font-size: 14px;
	}
  }
.userInfo{
	margin-top: 10px;
	text-align: center;
	font-size: 30px;
	height: 100%;
	width:100%;
	padding:15px;
	background-color: #aaaaad;
}

  @media screen and (max-width: 1600px) {
	.userInfo{
	  font-size: 20px;
	}
  }
.userInfoList{
	margin-top:10px;
	text-align: left;
	font-size: 20px;
	width:100%;
	height:100%;
	
}
@media screen and (max-width:1600px) {
	.userInfoList{
	  font-size: 15px;
	}
  }
.userInfoListItem{
	background-color:#333335;

}

.boxButton{
	margin-top:100px;
	margin-left: 120px;
	width:200px;
	height: 80px;
	text-align: center;
	font-size: 40px;

}
.modal-design{
	background-color: #DFDCE5FF;

}
.modal-header{
	background-color:#101820FF;
	color:white;
}
.modal-title{
	text-align: center;
	margin-left: 200px;
	font-size: 30px;
	font-weight: bold;
}
.modal-label{
	font-size:25px;
	font-weight: bold;
}
.modal-text{
	height: 50px;
	font-size:20px;
}
.modal-button{
	height:50px;
	width:100px;
	font-size:25px;

}
.modal-footer{
	display: flex;
    justify-content: center;
    align-items: center;

}
.extra1{
	height:30px;
}
.extra2{
	height:30px;
}
.extra3{
	height:140px;
}

.notePopUpTitle{
	color:white;
	text-align:center;
}
.homeModalButton{
	
	float: right;	
	
}
.dropdownForStatus{
	color:white;
	
}

.statusRunning{
	color:rgb(4, 167, 167);
}
.statusUpcoming{
	color:rgb(6, 6, 54);
}
.statusDelayed{
	color:orange;
}
.statusCompleted{
	color:#5fcd1a;
}

/* Dropdown block align forcely */
li{
	display: block !important;
	text-align: center !important;
}



* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.logo-background {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  /* background-color: #B61924; */
  background: rgb(120, 115, 191);
  background: linear-gradient(90deg, rgba(120, 115, 191, 1) 0%, rgba(68, 68, 190, 1) 31%, rgba(15, 189, 224, 1) 90%);
}

#ultra-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: fill 0.5s ease forwards 3.5s;
}

#ultra-logo path:nth-child(1) {
  stroke-dasharray: 483.892;
  stroke-dashoffset: 483.892;
  animation: line-anim 1.5s ease forwards 0.3s;
}

#ultra-logo path:nth-child(2) {
  stroke-dasharray: 239.328;
  stroke-dashoffset: 239.328;
  animation: line-anim 1.5s ease forwards 0.6s;
}

#ultra-logo path:nth-child(3) {
  stroke-dasharray: 314.949;
  stroke-dashoffset: 314.949;
  animation: line-anim 1.5s ease forwards 0.9s;
}

#ultra-logo path:nth-child(4) {
  stroke-dasharray: 246.024;
  stroke-dashoffset: 246.024;
  animation: line-anim 1.5s ease forwards 1.2s;
}

#ultra-logo path:nth-child(5) {
  stroke-dasharray: 506.445;
  stroke-dashoffset: 506.445;
  animation: line-anim 1.5s ease forwards 1.5s;
}

#ultra-logo path:nth-child(6) {
  stroke-dasharray: 137.087;
  stroke-dashoffset: 137.087;
  animation: line-anim 1.5s ease forwards 1.8s;
}

#ultra-logo path:nth-child(7) {
  stroke-dasharray: 477.369;
  stroke-dashoffset: 477.369;
  animation: line-anim 1.5s ease forwards 2.1s;
}

@keyframes line-anim {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes fill {
  from {
    fill: transparent;
  }
  to {
    fill: white;
  }
}

.logo-sub-name {
  position: absolute;

  /* top: 60%; */
  left: 50%;
  transform: translate(-50%);
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 96px;
  line-height: 144px;

  color: #ffffff;
  animation: line-up 2.5s ease forwards;
}
/* Asia Pacific */

@keyframes line-up {
  from {
    top: 80%;
  }
  to {
    top: 60%;

    /* transform: translate(-50%); */
  }
}

