@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

html,
body {
  height: 100%;
  /* background: #2a2b2e; */
  /* width: 100%; */
  margin: 0px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-family: "Roboto", sans-serif;
}

html,
body,
#root,
.App {
  height: 100%;
}

.body-container {
  display: grid;
  height: 100%;
  grid-template-columns: 12fr;
  grid-template-rows: 1fr 10fr 1fr;
}
.body-container > div {
  border: 1px solid black;
}

.header {
  background: #1f2023;
  border: 1px #5e5e5e solid;
  display: grid;
  grid-template-columns: 2fr 10fr;
}

.title {
  justify-self: center;
  align-self: center;
  font-size: 2rem;
  font-weight: 500;
  color: white;
}
.logout {
  justify-self: end;
  align-self: center;
  margin-right: 1rem;

  /* color: white; */
}
.logout > p {
  color: white;
  font-size: 10px;
  margin-top: auto;
  margin-bottom: 100px;
}

.profile-wrapper {
  background: #202124;
  /* display:#2a2b2e none; */
}

.profile-content-wrapper {
  width: 100%;
  /* max-width: 100vw; */
  display: grid;
  grid-template-columns: 2fr 10fr;
}
.profile-content-wrapper > div {
  border: 1px solid black;
}

.content-wrapper {
  background: #202124;
  display: grid;
  grid-template-rows: 1fr 11fr;
}
.content-table-wrapper {
  max-width: 100%;
  justify-self: stretch;
  font-size: 1rem;
}
@media only screen and (max-width: 1400px) {
  .content-table-wrapper {
    font-size: 0.9rem;
    padding-right: 0px;
    padding-right: 0px;
  }
}

.footer {
  background: #1f2023;
  border: 2px solid #454546;
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
}
.footer > p {
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: white;
  opacity: 0.5;
  font-size: 1em;
}

.alert-shown {
  opacity: 1;
  transition: all 250ms linear;
}

.alert-hidden {
  opacity: 0;
  transition: all 250ms linear 2s;
}

.content-nav-btn-wrapper {
  /* margin-top: 0px;
  margin-bottom: 0px;
  align-self: start; */
  margin-left: 1.5rem;
  display: flex;
  flex-wrap: nowrap;
  gap: 0.5rem;
  height: 2rem;
}
.custom-btn {
  /* height: 7.3vh; */
  height: 3rem;
  background: "FFFFFF";
  border-bottom: none;
}

/* Here we design the custom detail panel for VIPNoteTable-- Starts */
.custom-detail-panel-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: #515151; */
}

.custom-detail-panel {
  /* opacity: 0.5; */
  width: 50%;
  margin: 20px;
  font-size: 20;
  color: inherit;
  text-align: center;
  /* background: rgb(172, 88, 88); */
  background-color: inherit;
  /* background: #515151; */

  /* mix-blend-mode: difference; */
  display: grid;
  /* grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr); */
  grid-column-gap: 1rem;
  grid-row-gap: 0.5rem;
  grid-template-areas:
    "id id"
    "createdby  createdAt"
    "updatedby  updatedAt";
}

.custom-detail-panel-note_id {
  font-size: large;
  grid-area: id;
  justify-self: center;
  /* text-decoration: underline; */
}

.custom-detail-panel-user_fullname {
  grid-area: createdby;
  justify-self: center;
}

.custom-detail-panel-createdAt {
  grid-area: createdAt;
  justify-self: center;
}

.custom-detail-panel-updated_by_user_fullname {
  grid-area: updatedby;
  justify-self: center;
}

.custom-detail-panel-updatedAt {
  grid-area: updatedAt;
  justify-self: center;
}

/* Here we design the custom detail panel for VIPNoteTable-- Ends */

.MuiTableRow-hover:hover {
  background-color: red;
}

.custom-detail-panel-wrapper {
  animation: animatename 0.3s alternate;
  /* animation-direction: reverse; */
}

@keyframes animatename {
  0% {
    opacity: 0.5;
    transform: scaleY(0);
    transform-origin: 100% 0%;
    height: 0px;
  }

  100% {
    transform: scaleY(1);
    transform-origin: 100% 0%;
    height: 130px;
  }
}
.tableIcon {
  transition: transform 0.5s ease-in-out;
}

.tableIcon:hover {
  transform: rotate(360deg);
}

.downArrowImg {
  transform: rotate(180deg);
}
