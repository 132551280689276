
Form{
	/*background-color:#4c4d4f;
	size:200px;
	color:white;
	padding: 80px;
  //margin-top:130px;
  //margin-left: 800px;
  //margin-right:800px;
  margin:200px;
  border-radius:10px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);*/
  max-width: 500px;
  min-width: 300px;
  max-height: 700px;
  width: 30%;
  height: 70%;
  margin: 110px auto;
  color:white;
  background-color: #4c4d4f;
  border-radius: 25px;
  padding:50px;
}
@media screen and (max-width: 1920px) {
	Form{
		width:20%;
		margin-left:40%;
		margin-right:40%;
		height:50%;
		margin-bottom: 0px;
		padding:30px;
	}
  }

.logintitle{
	text-align: center;
}
@media screen and (max-width: 1920px) {
	.logintitle{
		font-size:20px;
	}
  }

.mb-3{
	font-size:30px;
}
@media screen and (max-width: 1920px) {
	.mb-3{
		font-size:15px;
	}
  }

.mb-2{
	font-size:20px;
}
@media screen and (max-width: 1920px) {
	.mb-2{
		font-size:15px;
	}
  }

Navbar{
	width:40px;
	margin:200px;
}
.loginButton{
	/*margin-top:20px;
	margin-left:150px;
	text-align:center;
	height:60px;
	width:120px;
	font-size: 25px;*/
	max-width: 200px;
  min-width: 100px;
  max-height: 150px;
  width: 50%;
  height: 70%;
  margin-top:10px;
  padding:10px;
  margin-left:25%;
  margin-right:25%;
  font-size: 25px;
}
@media screen and (max-width: 1920px) {
	.loginButton{
		font-size:15px;
	}
  }
.formtext{
	height: 50px;
	font-size:20px;
	
}
@media screen and (max-width: 1920px) {
	.formtext{
		height: 35px;
		font-size:15px;
	}
  }
.logoutButton{
	font-size:20px;
}
.or{
	text-align: center;
}
.modalButton{
	text-align: center;
	margin-left: 860px;
	height:40px;
	width:300px;
}
.modalTitle{
	text-align: center;
	margin-left: 100px;
	font-size: 20px;
	font-weight: bold;
}

.App {      
  text-align: center;      
}  
.nav-item{
  font-size: 40px;
  padding:15px;

}
.nav-logout{
	font-size: 30px;
	padding:10px;
	text-align: right;
	margin-left: 1600px;
}    
.navheader{            
  color :white;      
  background-color:#11142e;     
}

body{
	/* background-color:#ADEFD1FF;
	background: linear-gradient(90deg, rgba(120, 115, 191, 1) 0%, rgba(68, 68, 190, 1) 31%, rgba(15, 189, 224, 1) 90%); */
	/* overflow-x:hidden;
	overflow-y:hidden; */
	background-color: rgb(7, 3, 32); /* For browsers that do not support gradients */
    background-image: linear-gradient(to right, rgb(29, 29, 29) , rgb(66, 66, 66));
}
.loginpage{
	overflow-x:hidden;
	overflow-y:hidden;
}

/* .footer{
	margin-top:90px;
	padding:15px;
	text-align: center;
	background-color: #11142e;
	text-align: bottom;
} */


.sidebar1{
	float: left;
  width: 100%;
  height: 100%;
  background:#2a2b2e;
 
}
.bodyBox{
	margin-top:100px;
	margin-left: 150px;
	float: left;
  width: 800px;
  height: 600px;
  background: #ccc;
  
}

	.card{
		width:100%;
		
	}
	.loginImg{
		margin-top: 2px;
		margin-left:25%;
		margin-right:25%;
		width:50%;
		display: flex;
		justify-content: center;
		align-items: center;
		opacity:1;
		
	}
	
.img1{
	/* margin-top: 10px; */
	margin-left:25%;
	margin-right:25%;
	width:50%;
	display: flex;
    justify-content: center;
    align-items: center;
	opacity:0.9;
	
}
/* .card-img{
	margin-top: 30px;
	width:25%;
	display: flex;
    justify-content: center;
    align-items: center;
} */
@media screen and (max-width: 1920px) {
	.img1{
		/* margin-top: 10px; */
		width:40%;
		margin-left:30%;
		margin-right:30%;
	}
  }
.profileButton{
	
	margin-top:15px;
	font-size: 25px;
	margin-left:15%;
	margin-right:15%;
	width:70%;
	height:100%;
	/* display: flex;
    justify-content: center;
    align-items: center; */
}
@media screen and (max-width: 1920px) {
	.profileButton{
	  font-size: 14px;
	}
  }
.profilelist{
	color:white;
	
}
.profilelist1{
	padding:15px;
	font-size: 20px;
	color:white;
	font-weight: bold;
}
@media screen and (max-width: 1600px) {
	.profilelist1{
		padding:5px;
		font-size: 15px;
		color:white;
		font-weight: bold;
	}
  }
.cpButton{
	margin-top:20px;
	margin-bottom: 15%;
	margin-left: 15%;
	width:70%;
	height: 100%;
	font-size: 25px;

}
@media screen and (max-width: 1920px) {
	.cpButton{
	  font-size: 14px;
	}
  }
.userInfo{
	margin-top: 10px;
	text-align: center;
	font-size: 30px;
	height: 100%;
	width:100%;
	padding:15px;
	background-color: #aaaaad;
}

  @media screen and (max-width: 1600px) {
	.userInfo{
	  font-size: 20px;
	}
  }
.userInfoList{
	margin-top:10px;
	text-align: left;
	font-size: 20px;
	width:100%;
	height:100%;
	
}
@media screen and (max-width:1600px) {
	.userInfoList{
	  font-size: 15px;
	}
  }
.userInfoListItem{
	background-color:#333335;

}

.boxButton{
	margin-top:100px;
	margin-left: 120px;
	width:200px;
	height: 80px;
	text-align: center;
	font-size: 40px;

}
.modal-design{
	background-color: #DFDCE5FF;

}
.modal-header{
	background-color:#101820FF;
	color:white;
}
.modal-title{
	text-align: center;
	margin-left: 200px;
	font-size: 30px;
	font-weight: bold;
}
.modal-label{
	font-size:25px;
	font-weight: bold;
}
.modal-text{
	height: 50px;
	font-size:20px;
}
.modal-button{
	height:50px;
	width:100px;
	font-size:25px;

}
.modal-footer{
	display: flex;
    justify-content: center;
    align-items: center;

}
.extra1{
	height:30px;
}
.extra2{
	height:30px;
}
.extra3{
	height:140px;
}

.notePopUpTitle{
	color:white;
	text-align:center;
}
.homeModalButton{
	
	float: right;	
	
}
.dropdownForStatus{
	color:white;
	
}

.statusRunning{
	color:rgb(4, 167, 167);
}
.statusUpcoming{
	color:rgb(6, 6, 54);
}
.statusDelayed{
	color:orange;
}
.statusCompleted{
	color:#5fcd1a;
}

/* Dropdown block align forcely */
li{
	display: block !important;
	text-align: center !important;
}


