/* .editModel {
  width: 80%;
  max-width: 90% !important;

} */
.modal-content {
  background-color: #202124;
}
#contained-modal-title-vcenter {
  /* background-color: #202124; */
  padding-left: 0px;
  margin-left: 0px;
  margin: 0px;
  font-size: 1.5rem;
}

.close ::before {
  content: "X";
  visibility: visible;
  color: white;
  font-weight: bold;
}

.sr-only::before {
  visibility: hidden;
}

.close {
  /*background-color: red; */
  visibility: hidden;
  position: absolute;
  right: 12px;
  top: 10px;
  width: 20px;
  height: 20px;
  background-color: rgb(247, 12, 12, 0.5);
}

.userLevelAndPasswordCommon {
  font-weight: 900;
  font-size: larger;
  /* display: inline-block;
  color: white;
  border: 1px solid #515151;
  box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.2);
  vertical-align: middle;
  width: 100%;
  height: 100%;
  padding: 0.8rem;
  text-align: center; */
}

.userLevelAdmin {
  /* background: rgb(52, 52, 182); */
  color: rgb(185, 87, 42);
  color: rgba(26, 140, 197, 0.842);
}

.userLevelUser {
  /* background: rgb(34, 145, 34); */
  color: rgb(34, 145, 34);
}

.passwordDefault {
  color: rgb(218, 46, 46);
}
.passwordUpdated {
  color: rgb(34, 110, 145);
}


